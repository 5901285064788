.Loading {
  position: absolute;
  z-index: 10;
  z-index: 1000;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

.subcontainer {
  position: absolute;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  overflow-y: hidden;
}

.text {
  z-index: 3;

  font-size: 20.349px;
  font-weight: 500;
}

.bar {
  z-index: 3;

  width: 278px;
  height: 11px;
  flex-shrink: 0;

  border-radius: 22px;
  background: rgba(0, 0, 0, 0.50);
}

.percent {
  position: relative;
  width: 94px;
  height: 11px;
  flex-shrink: 0;

  border-radius: 22px;
  background: #0F8;
}

.shadow {
  z-index: 1;

  position: absolute;
  top: 25%;
  width: 277px;
  height: 277px;
  flex-shrink: 0;

  border-radius: 277px;
  background: #0F8;
  filter: blur(175px);
}

.title {
  z-index: 2;

  position: relative;
  top: 50px;
  font-size: 72.29px;
  font-weight: 800;

  background: linear-gradient(183deg, #FFF 49.81%, #0F8 133.23%);
  background-clip: text;
  -webkit-background-clip: text;

}

.title::before {
  z-index: 1;

  content: 'GEKKO';
  position: absolute;
  left: 15px;
  top: 5px;

  text-shadow: 0px 5.721px 49.63px rgba(0, 0, 0, 0.50);
  font-size: 64.361px;
  color: #247953;

}

.title::after {
  z-index: 3;

  content: "GEKKO";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(183deg, #FFF 49.81%, #0F8 133.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}