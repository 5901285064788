.Onboarding {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 100000;
}

.modalContent {
  position: absolute;
  padding: 25px 35px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #062E0C;
  font-size: 16px;
  font-weight: 700;

  border: 1px solid #01FF88;
  border-radius: 22px;
  background: rgba(160, 255, 211, 0.7);;
}

.button {
  position: absolute;

  bottom: 130px;
  
  display: flex;
  justify-content: center;
  align-items: center;

  width: 141px;
  height: 35px;
  background: #0F8;
  border-radius: 10px;

  color: #062E0C;
  font-size: 16px;
  font-weight: 700;
}

.abs {
  display: flex;
  padding: 0 23px;
  width: 100%;
  height: 102px;
  position: absolute;
  bottom: 0;
}

.circle {
  z-index: 3;

  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: #00FF88;
}

.image {
  z-index: 3;
  width: 30px;
}

.item {
  width: 100%;
  display: flex;
  justify-content: center;
}