.ActionModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 92px;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: end;
  z-index: 7;
}

.modalContent {
  position: relative;
  width: 100%;
  height: 40%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid rgb(0, 152, 81);
  border-radius: 12px 12px 0 0;
  background: #04180F;
}

.shadow {
  z-index: 1;
  position: absolute;
  bottom: -1px;
  left: -1.5px;
  width: calc(100% + 3px);
  height: 100%;

  background: linear-gradient(
        rgba(4, 24, 15, 0.1), /* Start color with transparency */
        rgba(4, 24, 15, 0.9),
        rgba(4, 24, 15, 1)  /* End color with transparency */
    );
}

.info {
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  position: relative;
  font-size: 24.351px;
  font-weight: 600;
  line-height: 32.629px; /* 133.996% */
  letter-spacing: -0.937px;
}

.gradient {
  z-index: 4;
  width: 100%;
  position: absolute;
  left: 0;
  font-size: 24.351px;
  font-weight: 600;
  line-height: 32.629px; /* 133.996% */
  letter-spacing: -0.937px;

  background: linear-gradient(180deg, #FFF 26.47%, #0F8 152.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.img {
  width: 43px;
  height: 43px;
}