.DailyTab {
  position: relative;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 12.705px;
  border: 1px solid rgb(0, 152, 81);
  background: #04180F;

  font-size: 16.824px;
  font-weight: 500;
  line-height: 22.544px; /* 133.996% */
  letter-spacing: -0.647px;

  margin-bottom: 5px;
}


.shadow {
  z-index: 1;
  position: absolute;
  bottom: -1px;
  left: -1.5px;
  width: calc(100% + 3px);
  height: 100%;

  border-radius: 0 0 12.705px 12.705px;
  background: linear-gradient(
        rgba(4, 24, 15, 0.1), /* Start color with transparency */
        rgba(4, 24, 15, 0.9),
        rgba(4, 24, 15, 1)  /* End color with transparency */
    );
}

.left {
  z-index: 3;

  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.right {
  z-index: 3;
}