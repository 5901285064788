.Friends {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #020B07;
  overflow: hidden;
}

.container {
  width: 100%;
  height: calc(100% - 92px);
  padding: 95px 28px 0 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.relative {
  position: relative;
  width: fit-content;
  height: fit-content;
  
}

.title {
  z-index: 4;
  text-align: center;
  position: relative;
  text-shadow: 0px 4.333px 37.592px rgba(0, 0, 0, 0.50);
  font-size: 33px;
  font-weight: 700;
  letter-spacing: -1.3px;
}

.gradient {
  z-index: 3;

  position: absolute;
  font-size: 33px;
  font-weight: 700;
  letter-spacing: -1.3px;

  background: linear-gradient(181deg, #FFF 49.92%, #0F8 239.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shadow {
  z-index: 2;

  position: absolute;
  top: 3px;
  font-size: 33px;
  font-weight: 700;
  letter-spacing: -1.3px;

  color: #247953;
}

.buttonContainer {
  position: relative;
  margin-bottom: 20px;
}

.stats {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  gap: 4px
}

.friendsList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.buttonImg {
  position: absolute;
  top: 9px;
  right: 13px;
}

.copy {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyButton {
  position: absolute;
  right: -39px;
  top: 0;
}

.copyMessage {
  position: absolute;
  top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  border-radius: 10px;
  color: #00FF88;
  background-color: rgba(0, 255, 136, 0.20);
}