.DailyModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: end;
  z-index: 1000;
}

.modalContent {
  position: relative;
  width: 100%;
  height: 83%;

  display: flex;
  justify-content: center;

  padding: 36px 50px 0 50px;

  border-top: 1px solid rgb(0, 152, 81);
  border-radius: 12px 12px 0 0;
  background: rgba(4, 24, 15, 0.50);
  backdrop-filter: blur(8.149999618530273px);
}

.days {
  width: 100%;
  gap: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}