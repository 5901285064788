.UpgradeCard {
  position: relative;
  width: 100%;
  height: 140px;
  max-width: 145px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;

  border-radius: 12.705px;
  border: 1px solid rgb(0, 152, 81);
  background: #04180F;
}


.shadow {
  z-index: 1;
  position: absolute;
  bottom: -1px;
  left: -1.5px;
  width: calc(100% + 3px);
  height: 100%;

  border-radius: 0 0 12.705px 12.705px;
  background: linear-gradient(
        rgba(4, 24, 15, 0.1), /* Start color with transparency */
        rgba(4, 24, 15, 0.9),
        rgba(4, 24, 15, 1)  /* End color with transparency */
    );
}

.name {
  z-index: 3;
}

.img {
  z-index: 3;

  position: absolute;
  bottom: 0;
  width: 76px;
}