.ActionModal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 92px;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}

.modalContent {
  position: relative;
  height: 50%;
  width: 80%;
  margin-bottom: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border: 1px solid rgb(0, 152, 81);
  border-radius: 12px;
  background: #04180F;
}

.title {
  z-index: 4;
  display: flex;
  position: relative;
  text-shadow: 0px 4.333px 37.592px rgba(0, 0, 0, 0.50);
  font-size: 33.793px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -1.3px;
}

.gradient {
  z-index: 3;

  position: absolute;
  font-size: 33.793px;
  font-weight: 700;
  letter-spacing: -1.3px;
  text-align: center;

  background: linear-gradient(181deg, #FFF 49.92%, #0F8 239.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shadow {
  z-index: 2;
  text-align: center;

  position: absolute;
  top: 3px;
  font-size: 33.793px;
  font-weight: 700;
  letter-spacing: -1.3px;

  color: #247953;
}

.button {
  background: #0f8;
  height: 50px;
  width: 226px;
  align-items: center;
  border-radius: 24px;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 17.161px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  padding: 0 17px;
  pointer-events: all;
  position: relative;
  z-index: 10;
  margin-top: 50px;
}


.gif {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 70%;
  bottom: -20px;
}