.League {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #020B07;
  overflow: hidden;
}

.container {
  width: 100%;
  height: calc(100% - 92px);
  padding: 20px 28px 0 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.relative {
  position: relative;
  width: fit-content;
  height: fit-content;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  
}

.title {
  z-index: 4;
  position: relative;
  text-shadow: 0px 4.333px 37.592px rgba(0, 0, 0, 0.50);
  font-size: 42px;
  font-weight: 700;
  letter-spacing: -1.3px;
  line-height: 35px;
}

.gradient {
  z-index: 3;

  position: absolute;
  font-size: 42px;
  font-weight: 700;
  letter-spacing: -1.3px;
  line-height: 35px;
  height: 120%;
}

.arrowLeft {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 130px;
  left: 10%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowRight {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 130px;
  right: 10%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.range {
  color: rgba(255, 255, 255, 0.50);
  text-align: center;
  font-size: 13.614px;
  font-weight: 500;
  line-height: 18.243px;
  letter-spacing: -0.524px;
  margin-bottom: 10px;
}


.stats {
  width: 100%;
  padding: 0 10%;
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.friendsList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.skinContainer {
  width: 75px;
  height: 160px;
}

.skin {
  width: 200%;
  margin: -50%;
  margin-top: -50px;
}