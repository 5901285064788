.Home {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: black;
  overflow: hidden;
}

.background {
  z-index: 1;
  
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 10%;
  object-fit: cover;
  
}

.shadow {
  z-index: 1;

  position: absolute;
  top: 25%;
  width: 277px;
  height: 277px;
  flex-shrink: 0;

  border-radius: 277px;
  background: #0F8;
  filter: blur(175px);
}

.container {
  z-index: 2;
  width: 100%;
  height: calc(100% - 92px);

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
}

.sign_container {
  width: 100%;

  display: flex;
  justify-content: space-around;
  pointer-events: all;
}

.sign {
  width: 128px;
  height: 83px;
  margin-top: 30px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 2px solid #136B27;
  border-radius: 15px;
}

@media screen and (max-height: 700px) {
  .sign {
    margin-top: 5px;
    width: 110px;
    height: 53px;
  }
}

.title {
  color: rgba(255, 255, 255, 0.70);

  font-size: 13.935px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-height: 700px) {
  .title {
    font-size: 11px;
  }
}

.value {
  font-size: 17.419px;
  font-weight: 700;

  background: linear-gradient(170deg, #FFF 38.82%, #432E17 158.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 22px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-height: 700px) {
  .main {
    margin-top: 0;
  }
}

.score {
  z-index: 4;

  position: relative;
  text-shadow: 0px 4.333px 37.592px rgba(0, 0, 0, 0.50);
  font-size: 48.75px;
  font-weight: 700;

  
}

.gradient {
  z-index: 3;

  position: absolute;
  font-size: 48.75px;
  font-weight: 700;

  background: linear-gradient(181deg, #FFF 49.92%, #0F8 239.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.score_shadow {
  z-index: 2;

  position: absolute;
  top: 5px;
  font-size: 48.75px;
  font-weight: 700;

  color: #247953;
}

.claim {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button {
  z-index: 3;
  position: relative;
  width: 226px;
  height: 50px;
  padding: 0 17px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 17.161px;
  font-weight: 600;
  color: #000000;

  border-radius: 24px;
  background: #0F8;

  cursor: pointer;
  pointer-events: all;
}

.blur {
  z-index: 2;

  position: absolute;
  left: 0;
  width: 226px;
  height: 50px;
  background: #0F8;
  filter: blur(31.149999618530273px);
}

.claim_text {
  z-index: 3;
}

.energy {
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: centers;
}

.energy_text {
  font-size: 17.161px;
  font-weight: 600;
}

.gekkoVid {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Видео будет масштабироваться, чтобы заполнить div, сохраняя соотношение сторон */
  pointer-events: none; /* Игнорируем события мыши на видео */
}

.gekkoVid::-webkit-media-controls {
  display: none !important;
}

.gekkoVid::-webkit-media-controls-start-playback-button {
  display: none !important;
}

.gekkoVid::-webkit-media-controls-overlay-play-button {
  display: none !important;
}

.gekkoVid::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

.gekkoVid::-webkit-media-controls-volume-slider {
  display: none !important;
}

.share {
  position: absolute;
  z-index: 100;
  width: 50px;
  height: 50px;
  background-color: black;
}

.coin {
  width: 44px;
  height: 44px;
  position: absolute;
  left: -40px;
  top: 7px;
}