.Button {
  position: relative;
  padding: 0 17px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  color: #000000;

  cursor: pointer;
}

.blur {
  position: absolute;
  left: 0;
  width: 100%;
  height: 80%;
  filter: blur(10px);
}

.text {
  z-index: 3;
}