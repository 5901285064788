.StatsTab {
  position: relative;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;

  border-radius: 12.705px;
  border: 1px solid rgb(0, 152, 81);
  background: #04180F;
}


.shadow {
  z-index: 1;
  position: absolute;
  bottom: -2px;
  left: -1.5px;
  width: calc(100% + 3px);
  height: 100%;

  border-radius: 0 0 12.705px 12.705px;
  background: linear-gradient(
        rgba(4, 24, 15, 0.1), /* Start color with transparency */
        rgba(4, 24, 15, 0.9),
        rgba(4, 24, 15, 1)  /* End color with transparency */
    );
}

.title {
  z-index: 1;

  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.298px;
  letter-spacing: -0.41px;
}

.stat {
  z-index: 1;

  position: relative;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.243px;
  letter-spacing: -0.524px;
}

.gradient {
  z-index: 2;
  left: 0;
  position: absolute;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.243px;
  letter-spacing: -0.524px;

  background: linear-gradient(180deg, #FFF 26.47%, #0F8 152.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}