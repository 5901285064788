.BigTab {
  position: relative;
  width: 100%;
  height: 82px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 12.705px;
  border: 1px solid rgb(0, 152, 81);
  background: #04180F;
}


.shadow {
  z-index: 1;
  position: absolute;
  bottom: -1px;
  left: -1.5px;
  width: calc(100% + 3px);
  height: 100%;

  border-radius: 0 0 12.705px 12.705px;
  background: linear-gradient(
        rgba(4, 24, 15, 0.1), /* Start color with transparency */
        rgba(4, 24, 15, 0.9),
        rgba(4, 24, 15, 1)  /* End color with transparency */
    );
}

.left {
  z-index: 3;

  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
}

.lvl {
  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 51px;
  height: 19px;
  border-radius: 17px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(9.25px);

  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.title {
  font-size: 16.765px;
  font-weight: 500;
  letter-spacing: -0.645px;
}

.subtitle {
  font-size: 13.009px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.50);
}

.rightMain {
  z-index: 3;
}

.rightSub {
  z-index: 3;
  font-size: 13.009px;
  font-weight: 500;
  line-height: 17.432px;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.50);
}

.miningImg {
  padding-bottom: 12px;
  padding-left: 5px;
}

.description {
  margin-left: 13px;
}