.Social {
  position: absolute;
  z-index: 6;
  width: 100%;
  height: calc(100% - 92px);

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #020B07;
}

.banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;
  padding: 24px 0 20px 28px;
  width: 100%;
  height: 200px;
  border-radius: 0px 0px 41px 40px;
  background: #0D462C;
}

.title {
  font-size: 23.383px;
  font-weight: 700;
  line-height: 31.333px; /* 133.996% */
  letter-spacing: -0.899px;
}

.container {
  width: 100%;
  padding: 16px 28px 0 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 92px;

}

.tasks {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.task {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.taskTitle {
  font-size: 17.075px;
  font-weight: 500;
  line-height: 22.879px;
  letter-spacing: -0.657px;
}

.rewardButton {
  width: 226px;
  height: 50px;
  font-size: 19px;
  border-radius: 24px;
  background: #0f8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 600;
}

.socials {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cross {
  position: absolute;
  right: 30px;
  font-size: 40px;
}
