.spinner {
  border: 8px solid #ffffff; /* Серый фон */
  border-top: 8px solid #00ff88; /* Цвет спиннера */
  border-radius: 50%;
  width: 50px; /* Ширина спиннера */
  height: 50px; /* Высота спиннера */
  animation: spin 1s linear infinite; /* Анимация вращения */
  margin: 20px auto; /* Центрирование спиннера */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
