.MenuItem {
  z-index: 2;
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8.75px;
  
  cursor: pointer;
}

.image {
  z-index: 3;
  width: 30px;
}

.title {
  color: rgba(255, 255, 255, 0.50);
  font-size: 12px;
  font-weight: 500;
}

.MenuItemActive {
  z-index: 2;

  position: relative;
  top: -11px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  cursor: pointer;
}

.circle {
  z-index: 3;

  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: #00FF88;
}

.line {
  z-index: 2;
  position: absolute;

  top: -13px;
  width: 75px;
  height: 75px;
  stroke-width: 5px;

  border: 5px solid #00FF88;
  border-radius: 50%;
}

.hider {
  z-index: 3;
  position: absolute;
  bottom: 0;
  width: 77px;
  height: 80px;
  background-color: #04180F;
}

.titleActive {
  z-index: 3;
  margin-top: 4px;
  color: #0F8;
  font-size: 12px;
  font-weight: 700;
}
