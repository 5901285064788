.UserTab {
  position: relative;
  width: 100%;
  height: 66px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 12.705px;
  border: 1px solid rgb(0, 152, 81);
  background: #04180F;
}


.shadow {
  z-index: 1;
  position: absolute;
  bottom: -1px;
  left: -1.5px;
  width: calc(100% + 3px);
  height: 100%;

  border-radius: 0 0 12.705px 12.705px;
  background: linear-gradient(
        rgba(4, 24, 15, 0.1), /* Start color with transparency */
        rgba(4, 24, 15, 0.9),
        rgba(4, 24, 15, 1)  /* End color with transparency */
    );
}

.userData {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.border {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  border-radius: 50%;
  padding: 5px;
  border: 1px solid #00FF88;
}

.blur {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #00FF88;
  border-radius: 50%;
  filter: blur(10px);
}

.img {
  z-index: 1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.name {
  text-align: left;
  font-size: 15.891px;
  font-weight: 600;
  letter-spacing: -0.802px;
}

.income {
  position: relative;
  font-size: 13.614px;
  font-weight: 600;
  line-height: 18.243px;
  letter-spacing: -0.524px;
}

.gradient {
  z-index: 4;
  width: 100%;
  position: absolute;
  left: 0;
  font-size: 13.614px;
  font-weight: 600;
  letter-spacing: -0.524px;

  background: linear-gradient(180deg, #FFF 26.47%, #0F8 152.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.right {
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rightTitle {
  text-align: center;
  font-size: 10.67px;
  font-weight: 500;
  letter-spacing: -0.41px;
}

.rightContent {
  position: relative;
  text-align: center;
  font-size: 17.301px;
  font-weight: 500;
  letter-spacing: -0.665px;
}

.rightGradient {
  z-index: 4;
  width: 100%;
  position: absolute;
  left: 0;
  font-size: 17.301px;
  font-weight: 700;
  letter-spacing: -0.665px;

  background: linear-gradient(180deg, #FFF 26.47%, #0F8 152.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info {
  text-align: left;
}