.NoPhone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #073d27;
}

.bg {
  position: absolute;
  height: 100%;
  object-fit: cover;
}

.buttons {
  position: absolute;
  bottom: 10%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button {
  width: 130px;
  height: 32px;
  border-radius: 12px;
  background-color: #00FF88;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: black;
  font-weight: bold;
}

.tiktok {
  width: 21px;
  height: 25px;
}

.inst {
  width: 25px;
  height: 25px;
}