.SmallTab {
  position: relative;
  width: 100%;
  height: 66px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 12.705px;
  border: 1px solid rgb(0, 152, 81);
  background: #04180F;
}


.shadow {
  z-index: 1;
  position: absolute;
  bottom: -1px;
  left: -1.5px;
  width: calc(100% + 3px);
  height: 100%;

  border-radius: 0 0 12.705px 12.705px;
  background: linear-gradient(
        rgba(4, 24, 15, 0.1), /* Start color with transparency */
        rgba(4, 24, 15, 0.9),
        rgba(4, 24, 15, 1)  /* End color with transparency */
    );
}

.left {
  z-index: 3;

  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.title {
  position: relative;
  font-size: 12.365px;
  font-weight: 500;
  letter-spacing: -0.645px;
  text-align: left;
}

.gradient {
  z-index: 4;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12.365px;
  font-weight: 500;
  letter-spacing: -0.645px;
  text-align: left;

  background: linear-gradient(180deg, #FFF 26.47%, #0F8 152.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  margin-top: 2px;
  font-size: 11.583px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.50);
}

.right {
  z-index: 3;
}

.img {
  width: 43px;
  height: 43px;
}