.UpgradeModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: end;
  z-index: 1000;
}

.modalContent {
  position: relative;
  width: 100%;
  height: 83%;

  display: flex;
  justify-content: center;

  border: 1px solid rgb(0, 152, 81);
  border-radius: 12px 12px 0 0;
  background: #04180F;
}

.shadow {
  z-index: 1;
  position: absolute;
  bottom: -1px;
  left: -1.5px;
  width: calc(100% + 3px);
  height: 100%;

  background: linear-gradient(
        rgba(4, 24, 15, 0.1), /* Start color with transparency */
        rgba(4, 24, 15, 0.9),
        rgba(4, 24, 15, 1)  /* End color with transparency */
    );
}

.info {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img {
  margin-top: -37px;
  width: 190px;
  height: 337px;
}

.title {
  font-size: 31.412px;
  font-style: normal;
  font-weight: 700;
  line-height: 42.091px; /* 133.996% */
  letter-spacing: -1.208px;
}

.bonus {
  color: rgba(255, 255, 255, 0.50);
  font-size: 23.335px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.268px; /* 133.996% */
  letter-spacing: -0.897px;
}

.req {
  font-size: 23.335px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.268px; /* 133.996% */
  letter-spacing: -0.897px;
  margin-bottom: 30px;
}