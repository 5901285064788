.ClickBubble {
  z-index: 10;
  position: absolute;
  font-size: 24px;
  font-weight: bold;
  animation: fadeOut 0.5s ease-out forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}