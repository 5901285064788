.Menu {
  z-index: 7;

  position: fixed;
  bottom: 0;

  width: 100%;
  height: 92px;
  padding: 0 23px;
  flex-shrink: 0;

  display: flex;

  border-radius: 12.705px 12.705px 0px 0px;
  border-top: 1px solid #0F8;
  background: #04180F;
}

.blur {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 191px;

  background: #091420;
  filter: blur(100px);
}